<template>
  <div class="body-wrapper">
    <Headernav />

    <div class="w-full bg-gray-100">

      <div class="w-full">
        <div class="container lg:pl-0 lg:pr-0 header-banner">
            <div class="w-full py-12"></div>
            <div class="w-full py-12 md:py-10"></div>
            <div class="w-full text-center text-white font-bold text-xl md:text-2xl lg:text-5xl uppercase tracking-wide leading-tight">
              Reliable, Affordable <br class="hidden md:block">and Good Quality
            </div>
            <div class="w-full py-12"></div>
            <div class="w-full py-12 md:py-10"></div>
        </div>
      </div>

      <div class="w-full">
          <div class="container lg:pl-0 lg:pr-0 lg:flex">

              <div class="w-full lg:w-8/12 lg:pr-12">
                  <div class="w-full">
                    <div class="w-full py-6"></div>
                    <div class="text-gray-600 font-semibold text-xl md:text-2xl lg:text-3xl tracking-wide leading-tight pb-8">
                      What We Offer 
                    </div>
                    <div class="text-gray-800 text-md md:text-lg leading-tight">
                      <ul class="list-disc pl-6">
                        <li class="pb-2">Direct Philippine Supplier of Fluted Wall Indoor Cladding Panel</li>
                        <li class="pb-2">Supplier of WPC / Fluted Ceiling Panel</li>
                        <li class="pb-2">Direct Supplier of Phenolic Toilet Partition and Cubicles. Customized sizes and various colors available.</li>
                        <li class="pb-2">Installation of Phenolic Toilet Partition (Luzon, Visayas and Mindanao)</li>
                        <li class="pb-2">Assistance in Design and Planning for PVC and Phenolic Toilet Partition</li>
                        <li class="pb-2">Direct Supplier of Laminated PVC Ceiling and Wall Panel (flat and ribbed-type)</li>
                        <li class="pb-2">Supply of Aluminum Profile and Accessories for Screen Doors and Windows</li>
                        <li class="pb-2">Proposals, Product Samples, Quotation and Estimation Upon Request</li>
                        <!-- <li class="pb-2">Direct Philippine Supplier of WPC Wall Cladding Panel and WPC Ceiling Panel</li>
                        <li class="pb-2">Supply and install of Phenolic Toilet Partition</li>
                        <li class="pb-2">Assistance in design and planning for PVC and Phenolic Toilet Partition</li>
                        <li class="pb-2">Estimation and supply for Laminated PVC Ceiling and Wall panel</li>
                        <li class="pb-2">Proposals, product samples and quotation including customized sizes</li>
                        <li class="pb-2">Delivery, ready stocks provided</li> -->
                      </ul>
                    </div>
                  </div>
              </div>

              <div class="w-full lg:w-4/12 contact-bg pl-6 pr-6">
                 <div class="w-full py-6"></div>

                  <div class="w-full text-center text-white font-semibold text-xl md:text-2xl lg:text-3xl tracking-wide leading-tight">
                    Inquire Now!
                  </div>
                  <div class="pb-12 pt-6">
                    <!-- feedback.activamedia.com.sg script begins here -->
                    <!-- <iframe allowTransparency="true" style="min-height:520px; height:inherit; overflow:auto;" width="100%" id="contactform123" name="contactform123" marginwidth="0" marginheight="0" frameborder="0" src="https://feedback.activamedia.com.sg/my-contact-form-5969202.html"><p>Your browser does not support iframes. The contact form cannot be displayed. Please use another contact method (phone, fax etc)</p></iframe> -->
                    <!-- feedback.activamedia.com.sg script ends here -->

                    <contactform />
                  </div>
              </div>

          </div>
      </div>
      
      <div class="w-full">
        <div class="container">
          <div class="w-full py-6"></div>
          <div class="text-gray-600 font-semibold text-xl md:text-2xl lg:text-3xl tracking-wide leading-tight pb-8">
            Our Projects
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project01.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project02.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project03.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project04.jpg" alt="Our Projects">
            </div>
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project05.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project06.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project07.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project08.jpg" alt="Our Projects">
            </div>
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project09.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project10.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project11.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project12.jpg" alt="Our Projects">
            </div>
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project13.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project14.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2">
              <img class="m-auto border border-gray-600" src="/images/projects/project15.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project16.jpg" alt="Our Projects">
            </div>
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project17.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2">
              <img class="m-auto border border-gray-600" src="/images/projects/project18.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project19.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project20.jpg" alt="Our Projects">
            </div>
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2">
              <img class="m-auto border border-gray-600" src="/images/projects/project21.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project22.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project23.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2">
              <img class="m-auto border border-gray-600" src="/images/projects/project24.jpg" alt="Our Projects">
            </div>
          </div>
          <div class="w-full md:flex">
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2">
              <img class="m-auto border border-gray-600" src="/images/projects/project25.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project26.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2 pb-8">
              <img class="m-auto border border-gray-600" src="/images/projects/project27.jpg" alt="Our Projects">
            </div>
            <div class="w-full md:w-4/12 md:pr-2 md:pl-2"></div>
          </div>
            <div class="w-full py-6"></div>
        </div>
      </div>


    </div>

    <div class="w-full">
       <div class="w-full py-6"></div>

        <div class="w-full">
          <div class="container lg:pl-0 lg:pr-0">
            <div class="w-full text-center text-gray-600 font-semibold text-xl md:text-2xl lg:text-3xl tracking-wide leading-tight pb-8">
              Our Main Products
            </div>
            <div class="w-full text-gray-600 font-semibold text-md md:text-xl leading-tight border-b-4 border-yellow-500 pb-2 mb-8">
              Phenolic Toilet Partition
            </div>
            <div class="w-full pb-12">
              <no-ssr>
                <carousel
                  class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="false" :nav="false" :rewind="true" :responsive="{
                    0: {
                      items: 1,
                      margin: 30
                    },
                    768: {
                      items: 3,
                      margin: 12
                    },
                    1024: {
                      items: 3,
                      margin: 40
                    }
                  }"
                  >

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product16.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product17.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product18.jpg" alt="Product">
                  </div>

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product05.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product06.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product01.jpg" alt="Product">
                  </div>
                  <!-- <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product02.jpg" alt="Product">
                  </div> -->
                  <!-- <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product03.jpg" alt="Product">
                  </div> -->
                  <!-- <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product04.jpg" alt="Product">
                  </div> -->
                  
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product07.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product08.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product09.jpg" alt="Product">
                  </div>
                  <!-- <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product10.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product11.jpg" alt="Product">
                  </div> -->
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product12.jpg" alt="Product">
                  </div>
                  
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product13.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product14.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/phenolic/product15.jpg" alt="Product">
                  </div>

                </carousel>
              </no-ssr>
            </div>

            <div class="w-full text-gray-600 font-semibold text-md md:text-xl leading-tight border-b-4 border-yellow-500 pb-2 mb-8">
              PVC Toilet Partition
            </div>
            <div class="w-full pb-12">
              <no-ssr>
                <carousel
                  class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="false" :nav="false" :rewind="true" :responsive="{
                    0: {
                      items: 1,
                      margin: 30
                    },
                    768: {
                      items: 3,
                      margin: 12
                    },
                    1024: {
                      items: 3,
                      margin: 40
                    }
                  }"
                  >

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc-toilet/product01.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc-toilet/product02.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc-toilet/product03.jpg" alt="Product">
                  </div>

                </carousel>
              </no-ssr>
            </div>

            <div class="w-full text-gray-600 font-semibold text-md md:text-xl leading-tight border-b-4 border-yellow-500 pb-2 mb-8">
              PVC Laminated Ceiling and Wall Panel
            </div>
            <div class="w-full pb-12">
              <no-ssr>
                <carousel
                  class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="false" :nav="false" :rewind="true" :responsive="{
                    0: {
                      items: 1,
                      margin: 30
                    },
                    768: {
                      items: 3,
                      margin: 12
                    },
                    1024: {
                      items: 3,
                      margin: 40
                    }
                  }"
                  >

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product08.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product09.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product10.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product11.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product01.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product02.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product03.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product04.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product05.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product06.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product07.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product12.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product13.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/pvc/product14.jpg" alt="Product">
                  </div>
                  

                </carousel>
              </no-ssr>
            </div>

            <div class="w-full text-gray-600 font-semibold text-md md:text-xl leading-tight border-b-4 border-yellow-500 pb-2 mb-8">
              Fluted Wall Panel and WPC / Fluted Ceiling Panel
            </div>
            <div class="w-full pb-12">
              <no-ssr>
                <carousel
                  class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="false" :nav="false" :rewind="true" :responsive="{
                    0: {
                      items: 1,
                      margin: 30
                    },
                    768: {
                      items: 3,
                      margin: 12
                    },
                    1024: {
                      items: 3,
                      margin: 40
                    }
                  }"
                  >

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product01.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product02.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product03.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product04.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product05.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product06.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product07.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product08.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product09.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product10.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product11.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product12.jpg" alt="Product">
                  </div>
                  <!-- <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product13.jpg" alt="Product">
                  </div> -->
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/wpc/product14.jpg" alt="Product">
                  </div>

                </carousel>
              </no-ssr>
            </div>

            <div class="w-full text-gray-600 font-semibold text-md md:text-xl leading-tight border-b-4 border-yellow-500 pb-2 mb-8">
              Aluminum Profiles, Windows and Screen
            </div>
            <div class="w-full pb-12">
              <no-ssr>
                <carousel
                  class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="false" :nav="false" :rewind="true" :responsive="{
                    0: {
                      items: 1,
                      margin: 40
                    },
                    768: {
                      items: 3,
                      margin: 12
                    },
                    1024: {
                      items: 3,
                      margin: 40
                    }
                  }"
                  >

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/aluminum-window/product01.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/aluminum-window/product02.jpg" alt="Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/main-products/aluminum-window/product03.jpg" alt="Product">
                  </div>

                </carousel>
              </no-ssr>
            </div>

          </div>
        </div>

       <div class="w-full py-6"></div>
    </div>

    <div class="w-full bg-gray-100">
      <div class="container lg:pl-0 lg:pr-0">
        <div class="w-full py-6"></div>
          <div class="w-full text-center text-gray-600 font-semibold text-xl md:text-2xl lg:text-3xl tracking-wide leading-tight pb-8">
            Other Products
          </div>

          <div class="w-full md:flex pb-8">
            <div class="w-full md:w-3/12 md:pr-4">
              <ul class="list-disc pl-6">
                <li class="text-gray-800 font-medium text-md md:text-lg leading-tight pb-2">HDF Laminated doors</li>
              </ul>
            </div>
            <div class="w-full md:w-3/12 md:pr-2 md:pl-2">
              <ul class="list-disc pl-6">
                <li class="text-gray-800 font-medium text-md md:text-lg leading-tight pb-2">G-lock Door Lockset</li>
              </ul>
            </div>
            <div class="w-full md:w-3/12 md:pr-2 md:pl-2">
              <ul class="list-disc pl-6">
                <li class="text-gray-800 font-medium text-md md:text-lg leading-tight pb-2">Shower enclosure patch fittings</li>
              </ul>
            </div>
            <div class="w-full md:w-3/12 md:pl-4">
              <ul class="list-disc pl-6">
                <li class="text-gray-800 font-medium text-md md:text-lg leading-tight pb-2">Portable Blower Fan</li>
              </ul>
            </div>
          </div>

          <div class="w-full pb-12">
              <no-ssr>
                <carousel
                  class="w-full" :autoHeight="true" :autoplay="true" :autoplayTimeout="5000" :dots="true" :loop="false" :nav="false" :rewind="true" :responsive="{
                    0: {
                      items: 1,
                      margin: 30
                    },
                    768: {
                      items: 3,
                      margin: 12
                    },
                    1024: {
                      items: 3,
                      margin: 40
                    }
                  }"
                  >

                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product01.jpg" alt="Other Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product02.jpg" alt="Other Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product04.jpg" alt="Other Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product03-new.jpg" alt="Other Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product05.jpg" alt="Other Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product06.jpg" alt="Other Product">
                  </div>
                  <div>
                      <img class="m-auto border border-gray-400" src="/images/other-products/product07.jpg" alt="Other Product">
                  </div>

                </carousel>
              </no-ssr>
            </div>

         <div class="w-full py-6"></div>
      </div>
    </div>

    <div class="w-full block lg:hidden">
      <img src="/images/about-profile-new.jpg" alt="Profile">
    </div>
    <div class="w-full about-bg">
      <div class="container lg:pl-0 lg:pr-0 py-6 lg:py-12">
         <div class="w-full py-6 md:py-6"></div>
        <div class="w-full lg:w-6/12">
          <div class="w-full text-gray-900 font-semibold text-xl md:text-2xl lg:text-3xl tracking-wide leading-tight uppercase pb-8">
            Golden Archer Marketing
          </div>
          <div class="text-gray-900 text-sm leading-tight text-justify pb-6">
            For many years, Golden Archer Marketing has served many construction companies, architects , contractors and households with a variety of construction materials.
          </div>
          <div class="text-gray-900 text-sm leading-tight text-justify pb-6">
            Our main product of Toilet Partition Cubicles is our expertise as we supply and install our cubicles all over the country. We guarantee our clients superb workmanship and quality HPL boards for commercial, leisure and public use that can last.
          </div>
          <div class="text-gray-900 text-sm leading-tight text-justify pb-6">
            Supply of Laminated PVC Ceiling and Wall Panels on the other hand is also provided by our company and is also our top quality product. We have two varieties to choose from: Plain Laminated Series and Ribbed-type Laminated Series.
          </div>
          <div class="text-gray-900 text-sm leading-tight text-justify">
            Our top commitment is to provide excellent service by making sure that we are consistent in distribution and supply of products needed. From time to time, we innovate and add a variety of new colors and designs. We also make sure that our clients are satisfied with our service by giving them technical support complete with shop drawings, customized boards and sizes according to their needs and after-sales update.
          </div>
        </div>
         <div class="w-full py-6"></div>
      </div>
    </div>

    <div class="w-full contact-bg py-12">
      <div class="container py-12">
        <div class="w-full">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.2313926809343!2d121.01843851484082!3d14.642802289775616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b659d66934c5%3A0xfe2e439987f0779e!2sGolden%20Archer%20Marketing!5e0!3m2!1sen!2sph!4v1628134389367!5m2!1sen!2sph" width="100%" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
        <div class="w-full text-center text-white font-bold text-lg uppercase pt-12">
          Office Address
        </div>
        <div class="w-full text-center pb-8">
          <span class="text-white font-light text-md">
            31 Aragon St., San Francisco Del Monte, Quezon City, Philippines
          </span>
        </div>

        <div class="w-full text-center text-white font-bold text-lg uppercase pb-2">
          Contact Number
        </div>
        <div class="w-full text-center pb-8">
          <div class="w-full text-center md:flex md:justify-center pb-2">
            <a href="tel:0283739932" target="_blank" class="text-white font-light text-md hover:text-yellow-400">02 83739932</a>
            <span class="hidden md:block text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <br class="block md:hidden">
            <a href="tel:0283403582" target="_blank" class="text-white font-light text-md hover:text-yellow-400">02 83403582</a>
          </div>

          <div class="w-full text-center md:flex md:justify-center pb-2">
            <a href="tel:0282837183" target="_blank" class="text-white font-light text-md hover:text-yellow-400">02 82837183</a>
            <span class="hidden md:block text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <br class="block md:hidden">
            <a href="tel:0289840582" target="_blank" class="text-white font-light text-md hover:text-yellow-400">02 89840582</a>
          </div>

          <div class="w-full text-center md:flex md:justify-center pb-2">
            <span class="text-white">Sun: </span><a href="tel:09228894538" target="_blank" class="text-white font-light text-md hover:text-yellow-400">09228894538</a>
            <span class="hidden md:block text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <br class="block md:hidden">
            <span class="text-white">Globe: </span><a href="tel:09178432026" target="_blank" class="text-white font-light text-md hover:text-yellow-400">09178432026</a>
          </div>
        </div>

        <div class="w-full text-center text-white font-bold text-lg uppercase pb-2">
          E-mail Address
        </div>
        <div class="w-full text-center md:flex md:justify-center pb-8">
          <a href="mailto:goldenarcher_phil@yahoo.com" target="_blank" class="text-white font-light text-md hover:text-yellow-400">goldenarcher_phil@yahoo.com</a>
          <span class="hidden md:block text-white">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <br class="block md:hidden">
          <a href="mailto:goldenarcher.mktg@gmail.com" target="_blank" class="text-white font-light text-md hover:text-yellow-400">goldenarcher.mktg@gmail.com</a>
        </div>

        <div class="w-full text-center pb-8">
          <img class="m-auto pb-4" src="/images/messenger-icon.png" alt="messenger">
          <span class="text-white font-light text-md">Messenger: Golden Archer Marketing</span>
        </div>
      </div>
    </div>

    <div class="w-full bg-black">
      <div class="container lg:pl-0 lg:pr-0 pt-5 pb-6">
        <div class="w-full text-sm text-white text-center">
          Maintained and Developed by AMPH Advertising Agency Inc.
        </div>
      </div>
    </div>

    <!-- ======= start: viber floating icon ======= -->
    <div>
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css">
      <a href="https://msng.link/vi/09178432026" class="viber-float hidden md:block" target="_blank">
        <div class="my-viber-float">
          <img class="m-auto" src="/images/viber-icon.png" alt="viber">
        </div>
      </a>
      <a href="https://msng.link/vi/09178432026" class="viber-float block md:hidden" target="_blank">
         <img class="m-auto mt-4" src="/images/viber-icon.png" alt="viber">
      </a>
    </div>

  </div>
</template>
<script>
import Headernav from '@/components/Headernav.vue'
import contactform from '@/components/contactform.vue'
import carousel from 'vue-owl-carousel'
import vueSmoothScroll from 'vue-smooth-scroll'
export default {
  components: {
    Headernav,
    contactform,
    vueSmoothScroll,
    carousel
  }
}
</script>
<style>
</style>
